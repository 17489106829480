import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAnchor.figmaUrl.android} codeUrl={checklists.componentAnchor.codeUrl.android} checklists={checklists.componentAnchor.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Anchor text is text that can be clicked and hyperlinked with other sites or pages. The use of anchor text is very important in the world of content marketing because it can increase index and ranking on search engines.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/android-compose-anchor.gif",
            "alt": "Legion Anchor Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants, Sizes, and States`}</h2>
    <p>{`Legion has 2 variants for Anchor Text:`}</p>
    <ol>
      <li parentName="ol">{`Left Icon`}</li>
      <li parentName="ol">{`Right Icon`}</li>
    </ol>
    <p>{`We have three sizes:`}</p>
    <ol>
      <li parentName="ol">{`Large Size`}</li>
      <li parentName="ol">{`Medium Size`}</li>
      <li parentName="ol">{`Small Size`}</li>
    </ol>
    <p>{`We have two states:`}</p>
    <ol>
      <li parentName="ol">{`Active`}</li>
      <li parentName="ol">{`Disabled`}</li>
    </ol>
    <h2>{`Usage`}</h2>
    <p>{`The following is code to implement Anchor Text component.`}</p>
    <h3>{`Left Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnAnchor(
        text = "Anchor",
        onClick = { /*TODO*/ },
        iconLeft = { Icon(imageVector = Icons.Default.DateRange, contentDescription = "icon") }
    )
`}</code></pre>
    <h3>{`Right Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    LgnAnchor(
        text = "Anchor",
        onClick = { /*TODO*/ },
        iconRight = { Icon(imageVector = Icons.Default.DateRange, contentDescription = "icon") }
    )
`}</code></pre>
    <h2>{`Sizes`}</h2>
    <h3>{`Large Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAnchor(
  (text = "Large Anchor"),
  (onClick = {
    /*TODO*/
  }),
  (size = LgnAnchorSize.Large)
);
`}</code></pre>
    <h3>{`Medium Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAnchor(
  (text = "Small Anchor"),
  (onClick = {
    /*TODO*/
  }),
  (size = LgnAnchorSize.Medium)
);
`}</code></pre>
    <h3>{`Small Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAnchor(
  (text = "Small Anchor"),
  (onClick = {
    /*TODO*/
  }),
  (size = LgnAnchorSize.Small)
);
`}</code></pre>
    <h2>{`States`}</h2>
    <h3>{`Active`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAnchor(
  (text = "Active Anchor"),
  (onClick = {
    /*TODO*/
  })
);
`}</code></pre>
    <h3>{`Disabled`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAnchor(
  (text = "Disabled Anchor"),
  (onClick = {
    /*TODO*/
  }),
  (enabled = false)
);
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the text of Anchor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action when Anchor is on Click`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnAnchorSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set size of Anchor by LgnAnchorSize.Large`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether the button can be pressed or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display left icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconRight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To display right icon`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnAnchorColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the color of the Anchor By LgnAnchorDefaults.colors()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set size of Anchor by LgnAnchorDefaults.shape`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[Modifier]`}{` to be applied to the anchor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interactionSource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MutableInteractionSource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[MutableInteractionSource]`}{` representing the source of the interactions`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      